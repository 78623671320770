module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Haus Bergkristall im Pitztal","short_name":"Haus Bergkristall","start_url":"/","lang":"de","background_color":"#bfbfbf","theme_color":"#1b1b1e","display":"standalone","icon":"src/images/logo.png","localize":[{"start_url":"/en/","lang":"en","name":"Haus Bergkristall im Pitztal","short_name":"Haus Bergkristall"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-141723183-2","anonymize":true},"googleTagManager":{},"facebookPixel":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["de","en"],"defaultLanguage":"de","redirect":true},
    }]
